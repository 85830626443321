import { DateTime } from 'luxon';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Button, Form, Badge } from 'react-bootstrap';
import { getRiskFreePlaysOfWallet, reprocessRiskFreePlays } from '../../../apis/campaigns.api';
import { useAuth } from '../../../contexts/auth.context';
import { customRound, formatDate } from '../../../utils/helpers';
import CampaignBreadcrumb from './CampaignBreadcrumbs';
// import CreateRiskFreePlayModal from './CreateRiskFreePlaysModal';
import ExportCSVButton from './ExportButton';

const RFPActionButtons = ({ play, authToken }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onClickedProcess = async () => {
    console.log(play);
    setLoading(true);
    try {
      await reprocessRiskFreePlays(play.campaignId, play.id, authToken);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
      return;
    }

    setLoading(false);
    setSuccess(true);
  };

  return (
    <>
      {play.status === 'PROCESSING' && (
        <>
          {!loading &&
            !success &&
            !error &&
            DateTime.fromISO(play.modifiedAt) < DateTime.utc().minus({ minutes: 1 }) && (
              <Button size="sm" variant="secondary" onClick={onClickedProcess}>
                <i className="fa-solid fa-arrow-rotate-right" />
              </Button>
            )}
          {loading && (
            <Button size="sm" variant="secondary" disabled>
              <i className="fa-solid fa-sync fa-spin" />
            </Button>
          )}
          {error && !loading && (
            <Button size="sm" variant="secondary" disabled>
              <i className="fa-solid fa-triangle-exclamation" />
            </Button>
          )}
          {success && !loading && (
            <Button size="sm" variant="secondary" disabled>
              <i className="fa-solid fa-circle-check" />
            </Button>
          )}
        </>
      )}
      {play.status !== 'PROCESSING' && (
        <Button size="sm" variant="secondary" disabled>
          <i className="fa-solid fa-slash" />
        </Button>
      )}
    </>
  );
};

const CampaignSearch = () => {
  const [walletIdInput, setWalletIdInput] = useState('');
  const [search, setSearch] = useState('');
  const [filteredRiskFreePlays, setFilteredRiskFreePlays] = useState([]);
  // const [showModal, setShowModal] = useState(false);

  const [riskFreePlays, setRiskFreePlays] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const { auth } = useAuth();
  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    authToken.current = auth?.idToken;
  }, [auth?.idToken]);

  const fetchRiskFreePlays = async walletId => {
    const response = await getRiskFreePlaysOfWallet(walletId, authToken.current);
    setRiskFreePlays(
      response
        .map(rfp => ({
          ...rfp,
          solAmountString: `${customRound(rfp.solAmount)} SOL`,
          playAmountString: `${rfp.playAmount ? `${customRound(rfp.playAmount)} SOL` : ''}`,
        }))
        .sort((a, b) => DateTime.fromISO(b.modifiedAt) - DateTime.fromISO(a.modifiedAt))
    );
  };

  useEffect(() => {
    const filterRiskFreePlays = () => {
      const searchLower = search.toLowerCase();
      const filtered = riskFreePlays.filter(play =>
        JSON.stringify(play).toLowerCase().includes(searchLower)
      );
      setFilteredRiskFreePlays(filtered);
    };

    filterRiskFreePlays();
  }, [search, riskFreePlays]);

  const getStatusBadge = status => {
    switch (status) {
      case 'REWARDED':
        return <Badge bg="success">REWARDED</Badge>;
      case 'REDEEMED':
        return <Badge bg="warning">REDEEMED</Badge>;
      case 'AVAILABLE':
        return <Badge bg="primary">AVAILABLE</Badge>;
      case 'EXPIRED':
        return <Badge bg="danger">EXPIRED</Badge>;
      case 'ACTIVATED':
        return <Badge bg="info">ACTIVATED</Badge>;
      case 'PROCESSING':
        return <Badge bg="danger">PROCESSING</Badge>;
      // type
      case 'GOOD_TIL_WIN':
        return <Badge bg="secondary">Good 'til Win</Badge>;
      default:
        return <Badge bg="dark">ACTIVATED</Badge>;
    }
  };

  // const renderStatusBadge = expireAt => {
  //   const isExpired = DateTime.fromISO(expireAt) < DateTime.utc();

  //   return <Badge bg={isExpired ? 'danger' : 'success'}>{isExpired ? 'Expired' : 'Active'}</Badge>;
  // };

  return (
    <Container className="pt-3" style={{ overflow: 'scroll' }}>
      <CampaignBreadcrumb currentName={`Searching...`} />
      {
        <>
          {/* <Row className="my-4">
            <Col>
              <h2>{campaign?.name}</h2>
              <h6>Expires at {formatDate(campaign.expireAt)}</h6>
              <h6>Created by {campaign.createdBy}</h6>
              <h6>{renderStatusBadge(campaign.expireAt)}</h6>
            </Col>
          </Row> */}
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Enter WalletId"
                value={walletIdInput}
                onChange={e => setWalletIdInput(e.target.value)}
              />
            </Col>
            <Col className="col-auto">
              <Button onClick={() => fetchRiskFreePlays(walletIdInput)}>
                <i className="fa-solid fa-search" /> Search
              </Button>
            </Col>
            <Col className="col-auto">
              <Button variant="secondary" onClick={() => setShowFilter(!showFilter)}>
                <i className="fa-solid fa-filter" />
              </Button>
            </Col>
            <Col className="col-auto">
              <ExportCSVButton data={filteredRiskFreePlays} />
            </Col>
          </Row>
          {showFilter && (
            <Row className="my-4">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Filter"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </Col>
            </Row>
          )}
        </>
      }
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Campaign</th>
                <th>ID</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Played</th>
                <th>Status</th>
                <th>Updated</th>
                <th>Expires</th>
                <th>Retry</th>
                <th>Redeemed</th>
                <th>Tx</th>
              </tr>
            </thead>
            <tbody>
              {filteredRiskFreePlays.map(play => (
                <tr key={play.id}>
                  <td>{play.campaignName}</td>
                  <td>{play.id}</td>
                  {/* <td>{play.walletId}</td> */}
                  <td>{play.reason?.slice(0, 10)}</td>
                  <td>{play.solAmountString}</td>
                  <td>{play.playAmountString}</td>
                  <td>{getStatusBadge(play.status)}</td>
                  <td>{formatDate(play.modifiedAt, DateTime.DATE_SHORT)}</td>
                  <td>{formatDate(play.expireAt, DateTime.DATE_SHORT)}</td>
                  <td>
                    <RFPActionButtons play={play} authToken={authToken.current} />
                  </td>
                  {/* <td>{getStatusBadge(play?.type?.split('_').join(' ') ?? 'DEFAULT')}</td> */}
                  <td>{play.gameId}</td>
                  <td>
                    {play?.signature?.length > 0 ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://solscan.io/tx/${play.signature}`}
                      >
                        <i className="d-inline-flex fas fa-external-link-alt fa-xs"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default CampaignSearch;
